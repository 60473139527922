import React, { useEffect, useRef, useState } from 'react';
import Isotope from 'isotope-layout';
import ProjectDetailsModal from './ProjectDetailsModal';
import { useTranslation } from 'react-i18next';
const Portfolio = () => {
	// init one ref to store the future isotope object
	const isotope = useRef();
	// store the filter keyword in a state
	const [filterKey, setFilterKey] = useState('*');
	const [imagesLoaded, setimagesLoaded] = useState(0);
	const [selectedProjectDetails, setSelectedProjectDetails] = useState();
	const [isOpen, setIsOpen] = useState(false);

	const { t, i18n } = useTranslation();

	const htmlElement = document.getElementsByTagName('html')[0];
	const isRtl = htmlElement.getAttribute('dir') === 'rtl';

	const filters = {
		COURSES: t('portfolio.filter-race'),
		AUTRE: t('portfolio.filter-other'),
	};

	const types = {
		IMAGE: 'image',
		VIDEO: 'video',
		DOCUMENT: 'document',
	};

	const projectsData =
		i18n.language === 'en'
			? [
					{
						title: 'Slalom of the YOG 2024 in Gangwon',
						type: types.IMAGE,

						thumbImage: 'images/Issa-4.jpeg',

						categories: [filters.COURSES],
					},
					{
						title: 'Giant of the YOG 2024 in Gangwon',
						type: types.IMAGE,

						thumbImage: 'images/Issa-5.jpeg',

						categories: [filters.COURSES],
					},
					{
						title:
							'With coach Alex at the finish of the Super-G at YOG 2024 in Gangwon',
						type: types.IMAGE,

						thumbImage: 'images/Issa-3.jpeg',

						categories: [filters.COURSES],
					},
					{
						title: 'Ski race in 2022',
						type: types.IMAGE,

						thumbImage: 'images/projects/course-1.jpg',

						categories: [filters.COURSES],
					},
					{
						title: 'Ski race in 2022',
						type: types.IMAGE,

						thumbImage: 'images/projects/course-2.jpg',

						categories: [filters.COURSES],
					},
					{
						title:
							'Meeting with the Kenyan Olympic Committee at the Meribel 2023 World Championships',
						type: types.IMAGE,

						thumbImage: 'images/projects/autre-2.jpg',

						categories: [filters.AUTRE],
					},
					{
						title:
							'Meeting with Sabrina at the Meribel 2023 Ski World Championships',
						type: types.IMAGE,

						thumbImage: 'images/projects/autre-1.jpg',

						categories: [filters.AUTRE],
					},
					{
						title: 'Podium in Oz',
						type: types.IMAGE,

						thumbImage: 'images/projects/course-4.jpg',

						categories: [filters.COURSES],
					},

					{
						title: `Family ski touring outing`,
						type: types.IMAGE,

						thumbImage: 'images/projects/rando-1.jpg',

						categories: [filters.AUTRE],
					},
					{
						title: `My first ski tracks`,
						type: types.IMAGE,

						thumbImage: 'images/projects/autre-3.jpg',

						categories: [filters.AUTRE],
					},
					{
						title: `Ski race in 2012`,
						type: types.IMAGE,

						thumbImage: 'images/projects/course-3.jpg',

						categories: [filters.COURSES],
					},
			  ]
			: [
					// {
					// 	title: 'Course à Vaujany',
					// 	type: types.DOCUMENT,
					// 	document: {
					// 		projectInfo:
					// 			'Première course de la saison, podium à la clef ! Trop content ! ',
					// 		place: 'Vaujany',
					// 		date: '1 janvier 2022',
					// 		sliderImages: ['images/projects/course-2.jpg'],
					// 	},

					// 	thumbImage: 'images/projects/course-1.jpg',

					// 	categories: [filters.COURSES],
					// },
					{
						title: 'Slalom des JOJ 2024 à Gangwon',
						type: types.IMAGE,

						thumbImage: 'images/Issa-4.jpeg',

						categories: [filters.COURSES],
					},
					{
						title: 'Géant des JOJ 2024 à Gangwon',
						type: types.IMAGE,

						thumbImage: 'images/Issa-5.jpeg',

						categories: [filters.COURSES],
					},
					{
						title:
							"Avec coach Alex à l'arrivée du super-G des JOJ 2024 à Gangwon",
						type: types.IMAGE,

						thumbImage: 'images/Issa-3.jpeg',

						categories: [filters.COURSES],
					},
					{
						title: 'Course en 2022',
						type: types.IMAGE,

						thumbImage: 'images/projects/course-1.jpg',

						categories: [filters.COURSES],
					},
					{
						title: 'Course en 2022',
						type: types.IMAGE,

						thumbImage: 'images/projects/course-2.jpg',

						categories: [filters.COURSES],
					},
					{
						title:
							'Rencontre avec le commité Olympique Kenyan aux Mondiaux de Meribel 2023',
						type: types.IMAGE,

						thumbImage: 'images/projects/autre-2.jpg',

						categories: [filters.AUTRE],
					},
					{
						title: 'Rencontre avec Sabrina aux mondiaux se ski de Meribel 2023',
						type: types.IMAGE,

						thumbImage: 'images/projects/autre-1.jpg',

						categories: [filters.AUTRE],
					},
					{
						title: 'Podium à Oz',
						type: types.IMAGE,

						thumbImage: 'images/projects/course-4.jpg',

						categories: [filters.COURSES],
					},
					// {
					// 	title: 'YouTube Video',
					// 	type: types.VIDEO,
					// 	video: {
					// 		vimeo: false,
					// 		id: 'PMNnEEEacCg',
					// 	},
					// 	thumbImage: 'images/projects/project-3.jpg',

					// 	categories: [filters.YOUTUBE],
					// },
					{
						title: `Sortie Ski de Rando en famille`,
						type: types.IMAGE,

						thumbImage: 'images/projects/rando-1.jpg',

						categories: [filters.AUTRE],
					},
					{
						title: `Mes premières traces à ski`,
						type: types.IMAGE,

						thumbImage: 'images/projects/autre-3.jpg',

						categories: [filters.AUTRE],
					},
					{
						title: `Course en 2012`,
						type: types.IMAGE,

						thumbImage: 'images/projects/course-3.jpg',

						categories: [filters.COURSES],
					},
			  ];

	// initialize an Isotope object with configs
	useEffect(() => {
		isotope.current = new Isotope('.portfolio-filter', {
			itemSelector: '.filter-item',
			layoutMode: 'masonry',
			originLeft: !isRtl,
		});

		// cleanup
		return () => {
			isotope.current.destroy();
		};
	}, []);

	// handling filter key change
	useEffect(() => {
		if (imagesLoaded) {
			filterKey === '*'
				? isotope.current.arrange({ filter: `*` })
				: isotope.current.arrange({ filter: `.${filterKey}` });
		}
	}, [filterKey, imagesLoaded]);

	const handleFilterKeyChange = (key) => () => setFilterKey(key);

	const getKeyByValue = (value) => {
		return Object.keys(filters).find((key) => filters[key] === value);
	};

	const getFilterClasses = (categories) => {
		if (categories.length > 0) {
			let tempArray = [];
			categories.forEach((category, index) => {
				tempArray.push(getKeyByValue(category));
			});
			return tempArray.join(' ');
		}
	};

	return (
		<>
			<section id='portfolio' className={'section bg-light'}>
				<div className={'container'}>
					{/* Heading */}
					<p className='text-center mb-2 wow fadeInUp'>
						<span className='bg-primary text-dark px-2'>
							{t('portfolio.section-title')}
						</span>
					</p>
					<h2 className='text-10 fw-600 text-center mb-5 wow fadeInUp'>
						{t('portfolio.section-subtitle')}{' '}
					</h2>
					{/* Heading end*/}
					{/* Filter Menu */}
					<ul
						className={
							'portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp'
						}
					>
						<li className='nav-item'>
							<button
								className={'nav-link ' + (filterKey === '*' ? 'active' : '')}
								onClick={handleFilterKeyChange('*')}
							>
								{t('portfolio.filter-all')}
							</button>
						</li>
						{Object.keys(filters).map((oneKey, i) => (
							<li className='nav-item' key={i}>
								<button
									className={
										'nav-link ' + (filterKey === oneKey ? 'active' : '')
									}
									onClick={handleFilterKeyChange(oneKey)}
								>
									{filters[oneKey]}
								</button>
							</li>
						))}
					</ul>
					{/* Filter Menu end */}
					<div className='portfolio wow fadeInUp'>
						<div className='row portfolio-filter filter-container g-4'>
							{projectsData.length > 0 &&
								projectsData.map((project, index) => (
									<div
										className={
											'col-sm-6 col-lg-4 filter-item ' +
											getFilterClasses(project.categories)
										}
										key={index}
									>
										<div className='portfolio-box'>
											<div className='portfolio-img'>
												<img
													onLoad={() => {
														setimagesLoaded(imagesLoaded + 1);
													}}
													className='img-fluid d-block portfolio-image'
													src={project.thumbImage}
													alt=''
												/>
												<div
													className='portfolio-overlay'
													onClick={() => {
														setSelectedProjectDetails(projectsData[index]);
														setIsOpen(true);
													}}
												>
													<button className='popup-ajax stretched-link border-0 p-0 '>
														{' '}
													</button>
													<div className='portfolio-overlay-details'>
														<p className='text-primary text-8'>
															{project.type === types.DOCUMENT && (
																<i className='fas fa-file-alt'></i>
															)}
															{project.type === types.IMAGE && (
																<i className='fas fa-image'></i>
															)}
															{project.type === types.VIDEO && (
																<i className='fas fa-video'></i>
															)}
														</p>
														<h5 className='text-white text-5'>
															{project?.title}
														</h5>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
			</section>
			{/* Modal */}
			{isOpen && (
				<ProjectDetailsModal
					projectDetails={selectedProjectDetails}
					setIsOpen={setIsOpen}
				></ProjectDetailsModal>
			)}
		</>
	);
};

export default Portfolio;
