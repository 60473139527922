import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// 	const { t } = useTranslation()
// {t('header.intro')}

const resources = {
	en: {
		translation: {
			button: {
				contact: 'Get in touch',
				'learn-more': 'Learn more about me',
				send: 'Send',
				cta: `Let's get in touch !`,
			},
			header: {
				intro: 'Issa - Alpine Skier',
				home: 'Home',
				about: 'About',
				project: 'My Project',
				experience: 'My Experience',
				portfolio: 'My Portfolio',
				faq: 'Frequently Asked Questions',
				supports: 'They support me',
				contact: 'Contact',
			},
			hero: {
				title: 'I am Issa',
				subtitle: 'Alpine Skier',
			},
			about: {
				'section-title': 'About',
				'section-subtitle': 'Who am I?',
				'text-title': 'My name is',
				'text-subtitle': 'Issa',
				'text-content':
					"I am a young Kenyan athlete, born in Alpe d'Huez to a French father who is a ski patrol rescuer and a Kenyan mother who is a childcare assistant. Skiing has always been a passion for me, and I started competing at the age of 5, winning my first medal that same year. In 2024, I had the honor of being the first male representative of Kenya 🇰🇪 in a Winter Olympics.",
				'category-text': 'U18',
				'category-label': 'FIS Category',
				'name-label': 'Name',
				'name-text': 'Issa Laborde Gachingiri',
				'email-label': 'Email',
				'email-text': 'issa.lab@yahoo.com',
				'birthday-label': 'Date of birth',
				'birthday-text': 'March 12, 2007',
				'living-place-label': 'From',
				'living-place-text': 'Grenoble, France',
			},
			project: {
				'project-list': [
					{
						name: 'Youth Olympic Games',
						desc: 'Participate in the 2024 Youth Olympic Games in South Korea ✅',
						icon: 'fas fa-skiing',
					},
					{
						name: 'Winter Olympics',
						desc: 'Represent Kenya in alpine skiing at the 2026 Winter Olympics in Milan Cortina',
						icon: 'fas fa-trophy',
					},
					{
						name: 'World Championships',
						desc: 'Compete in the 2025 Junior World Championships in Saalbach Austria',
						icon: 'fas fa-mountain',
					},
				],
				'section-title': 'My Goals',
				'section-subtitle': 'My goal: Represent Kenya in alpine skiing',
				'text-content':
					'I want to represent Africa and my Kenyan roots in high-level competitions. My goal is not only to introduce skiing to African countries, but also to share my inspiring story, to promote my flag and to showcase my passion for alpine skiing in places where this sport is less known.',
			},
			resume: {
				'section-title': 'My Experience',
				'section-subtitle': 'My background and My strong points',
				'path-title': 'Path',
				'performances-title': 'Experience',
			},
			skills: {
				'section-title': `My Skills`,
				slalom: 'Slalom',
				giant: 'Giant',
				superG: 'Super-G',
			},
			portfolio: {
				'section-title': 'My Portfolio',
				'section-subtitle':
					'Some pictures of my passion for skiing, and more !',
				'filter-all': 'All',
				'filter-race': 'Races',
				'filter-other': 'Various',
			},
			faq: {
				'section-title': 'Frequently asked questions',
				'section-subtitle': 'The key points of my project',
			},
			testimonials: {
				'section-title': 'Reviews',
				'section-subtitle': 'They support me',
			},
			contact: {
				'section-title': `Contact Me`,
				'text-content': `Do not hesitate to contact me for any questions or support projects. I will be happy to talk with you!`,
				'email-label': 'Email',
				'email-text': 'issa.lab@yahoo.com',
				'phone-label': 'Phone',
				'phone-text': '+33 6 60 64 06 33',
				'form-name-label': 'Your Name',
				'form-email-label': 'Your Email',
				'form-text-label': 'A question? A project?',
				'cta-title': 'You want to support me ?',
			},
		},
	},
	fr: {
		translation: {
			button: {
				contact: 'Me contacter',
				'learn-more': 'En savoir plus sur moi',
				send: 'Envoyer',
				cta: 'Contactez Moi !',
			},
			header: {
				intro: 'Issa - Skieur Alpin',
				home: 'Accueil',
				about: 'A propos',
				project: 'Mon Projet',
				experience: 'Mon Expérience',
				portfolio: 'Mon Portfolio',
				faq: 'Questions Fréquentes',
				supports: 'Ils me soutiennent',
				contact: 'Contact',
			},
			hero: {
				title: `Moi c'est Issa`,
				subtitle: 'Skieur Alpin',
			},
			about: {
				'section-title': 'A propos',
				'section-subtitle': 'Qui suis-je ?',
				'text-title': `Je m'apelle`,
				'text-subtitle': 'Issa',
				'text-content': `
				Je suis un jeune athlète kenyan, né à l'Alpe d'Huez d'un père français pisteur-secouriste et d'une mère kenyane assistante maternelle. Le ski a toujours été une passion pour moi, et j'ai commencé la compétition à l'âge de 5 ans, remportant ma première médaille cette année-là. En 2024, j'ai eu l'honneur d'être le premier représentant masculin du Kenya 🇰🇪 dans une olympiade d'hiver.`,
				'category-text': 'U18',
				'category-label': 'Catégorie FIS',
				'name-label': 'Nom',
				'name-text': 'Issa Laborde Gachingiri',
				'email-label': 'Email',
				'email-text': 'issa.lab@yahoo.com',
				'birthday-label': 'Date de naissance',
				'birthday-text': '12 Mars 2007',
				'living-place-label': 'Originaire de',
				'living-place-text': 'Grenoble, France',
			},
			project: {
				'project-list': [
					{
						name: 'J.O de la Jeunesse',
						desc: 'Participer aux Jeux Olympiques de la jeunesse 2024 en Corée du Sud ✅',
						icon: 'fas fa-skiing',
					},
					{
						name: `J.O d'hiver`,
						desc: `Représenter le Kenya en ski Alpin aux Jeux Olympiques d'hiver de Milan Cortina 2026`,
						icon: 'fas fa-trophy',
					},
					{
						name: 'Championnats du Monde',
						desc: 'Concourir aux Championnats du Monde Junior 2025 de Saalbach en Autriche ',
						icon: 'fas fa-mountain',
					},
				],
				'section-title': 'Mes Objectifs',
				'section-subtitle': 'Mon but : Représenter le Kenya en ski alpin',
				'text-content': `Je souhaite représenter l'Afrique et mes racines kenyanes sur des
				compétitions de haut niveau. Mon objectif n'est pas seulement de faire
				découvrir le ski aux pays Africains, mais aussi de partager mon
				histoire inspirante, de faire rayonner mon drapeau et de mettre en
				lumière ma passion pour le ski alpin dans des endroits où ce sport est
				moins connu.`,
			},
			resume: {
				'section-title': 'Mon expérience',
				'section-subtitle': 'Mon parcours et mes points forts',
				'path-title': 'Parcours',
				'performances-title': 'Experience',
			},
			skills: {
				'section-title': `Mes Compétences`,
				slalom: 'Slalom',
				giant: 'Géant',
				superG: 'Super-G',
			},
			portfolio: {
				'section-title': 'Mon Portfolio',
				'section-subtitle':
					'Quelques photos de ma passion pour le ski, mais pas seulement !',
				'filter-all': 'Tous',
				'filter-race': 'Courses',
				'filter-other': 'Divers',
			},
			faq: {
				'section-title': 'Questions Fréquentes',
				'section-subtitle': 'Les points clés de mon projet',
			},
			testimonials: {
				'section-title': 'Avis',
				'section-subtitle': 'Ils me soutiennent',
			},
			contact: {
				'section-title': `Me contacter`,
				'text-content': `N'hesitez pas à prendre contact avec moi pour toute question ou
				projet de soutient. Je me ferai un plaisir d'échanger à vous !`,
				'email-label': 'Email',
				'email-text': 'issa.lab@yahoo.com',
				'phone-label': 'Téléphone',
				'phone-text': '+33 6 60 64 06 33',
				'form-name-label': 'Votre Nom',
				'form-email-label': 'Votre Email',
				'form-text-label': 'Une question ? Un projet ?',
				'cta-title': 'Envie de me soutenir ?',
			},
		},
	},
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		fallbackLng: 'fr',

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});

export default i18n;
