import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const LanguageSwitcher = () => {
	const { i18n, t } = useTranslation();

	const [lang, setLang] = useState(i18n.language);

	let changeLanguage = (event) => {
		let language = event.target.value;

		switch (language) {
			case 'en':
				setLang('en');
				i18n.changeLanguage('en');
				break;
			case 'fr':
			default:
				setLang('fr');
				i18n.changeLanguage('fr');
				break;
		}
	};

	return (
		<div className='form-control w-auto h-auto bg-transparent border-0 pt-5'>
			<select
				className='form-select text-white bg-transparent border-0 '
				value={lang}
				name='language'
				onChange={changeLanguage}
			>
				<option value='fr'>Français 🇫🇷</option>
				<option value='en'>English 🇬🇧</option>
			</select>
		</div>
	);
};
