import React from 'react';
import { useTranslation } from 'react-i18next';

const Resume = () => {
	const { t, i18n } = useTranslation();
	const educationDetails =
		i18n.language === 'en'
			? [
					{
						yearRange: '2021 - Present',
						title: 'Ski Club',
						place: 'Vaujany',
						desc: 'Competition Section',
					},
					{
						yearRange: '2017 - 2021',
						title: 'Ski Club',
						place: 'Chamrousse',
						desc: 'Competition Section',
					},
					{
						yearRange: '2016',
						title: 'Ski Club',
						place: 'GUC Grenoble',
						desc: 'Competition Section',
					},
					{
						yearRange: '2012-2015',
						title: 'Pre-Club Section',
						place: "L'Alpe d'Huez",
						desc: 'Progression before joining the ski club',
					},
					{
						yearRange: '2010',
						title: 'ESF',
						place: "L'Alpe d'Huez",
						desc: 'Ski School',
					},
			  ]
			: [
					{
						yearRange: `2021 - Aujourd'hui`,
						title: 'Ski Club',
						place: 'Vaujany',
						desc: 'Section Compétition',
					},
					{
						yearRange: '2017 - 2021',
						title: 'Ski Club',
						place: 'Chamrousse',
						desc: 'Section Compétition',
					},
					{
						yearRange: '2016',
						title: 'Ski Club',
						place: 'GUC Grenoble',
						desc: 'Section Compétition',
					},
					{
						yearRange: '2012-2015',
						title: 'Section Pré-club',
						place: `L'Alpe d'Huez`,
						desc: `Progression avant d'intégrer le ski-club`,
					},
					{
						yearRange: '2010',
						title: `ESF`,
						place: `L'Alpe d'Huez`,
						desc: 'Ecole de ski',
					},
			  ];

	const experienceDetails =
		i18n.language === 'en'
			? [
					{
						yearRange: '2024',
						title: `Youth Olympic Games`,
						place: 'Gangwon, South Korea',
						desc: `Participation in the YOG under the kenyan Banner 🇰🇪. First Kenyan male to represent Kenya in Winter Olympics`,
					},
					{
						yearRange: '2023',
						title: `Scara Val d'Isère`,
						place: 'Vaujany Ski Club',
						desc: `Participation in the Scara international race at Val d'Isère under the Kenyan banner 🇰🇪`,
					},
					{
						yearRange: '2022',
						title: `Coupe d'Argent`,
						place: 'Vaujany Ski Club',
						desc: 'Top 15 in the « Coupe d’argent » ranking of Banque Populaire Auvergne Rhône-Alpes',
					},
			  ]
			: [
					{
						yearRange: '2024',
						title: `Jeux Olympiques de la Jeunesse`,
						place: 'Gangwon, Corée du Sud',
						desc: `Participation aux Jeux Olympiques de la Jeunesse sous bannière Kenyane 🇰🇪. Premier homme Kenyan sur une Olympiade d'hiver.`,
					},
					{
						yearRange: '2023',
						title: `Scara Val d'Isère`,
						place: 'Vaujany Ski Club',
						desc: `Participation à la course internationale de la Scara à Val d'Isère sous bannière Kenyane 🇰🇪`,
					},
					{
						yearRange: '2022',
						title: `Coupe d'Argent`,
						place: 'Ski Club de Vaujany',
						desc: 'Top 15 du classement « Coupe d’argent » Banque Populaire Auvergne Rhône-Alpes',
					},
			  ];

	const skills = [
		{
			name: t('skills.slalom'),
			percent: 90,
		},
		{
			name: t('skills.giant'),
			percent: 70,
		},
		{
			name: t('skills.superG'),
			percent: 50,
		},
	];

	return (
		<section id='resume' className='section'>
			<div className='container'>
				{/* Heading */}
				<p className=' text-center mb-2 wow fadeInUp'>
					<span className='bg-primary text-dark px-2'>
						{' '}
						{t('resume.section-title')}
					</span>
				</p>
				<h2 className='text-10 fw-600 text-center mb-5 wow fadeInUp'>
					{t('resume.section-subtitle')}
				</h2>
				{/* Heading end*/}
				<div className='row g-5 mt-5'>
					{/* My Education */}
					<div className='col-lg-6 wow fadeInUp'>
						<h2 className='text-7 fw-600 mb-4 pb-2'>
							{t('resume.path-title')}
						</h2>
						<div className='border-start border-2 border-primary ps-3'>
							{educationDetails.length > 0 &&
								educationDetails.map((value, index) => (
									<div key={index}>
										<h3 className='text-5'>{value.title}</h3>
										<p className='mb-2'>
											{value.place} / {value.yearRange}
										</p>
										<p className='text-muted'>{value.desc}</p>
										<hr className='my-4' />
									</div>
								))}
						</div>
					</div>
					{/* My Experience */}
					<div className='col-lg-6 wow fadeInUp' data-wow-delay='0.2s'>
						<h2 className='text-7 fw-600 mb-4 pb-2'>
							{t('resume.performances-title')}
						</h2>
						<div className='border-start border-2 border-primary ps-3'>
							{experienceDetails.length > 0 &&
								experienceDetails.map((value, index) => (
									<div key={index}>
										<h3 className='text-5'>{value.title}</h3>
										<p className='mb-2'>
											{value.place} / {value.yearRange}
										</p>
										<p className='text-muted'>{value.desc}</p>
										<hr className='my-4' />
									</div>
								))}
						</div>
					</div>
				</div>
				{/* My Skills */}
				<h2 className='text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp'>
					{t('skills.section-title')}
				</h2>
				<div className='row gx-5'>
					{skills.length > 0 &&
						skills.map((skill, index) => (
							<div className='col-md-6 wow fadeInUp' key={index}>
								<p className='fw-500 text-start mb-2'>
									{skill.name}{' '}
									{/* <span className='float-end'>{skill.percent}%</span> */}
								</p>
								<div className='progress progress-sm mb-4'>
									<div
										className='progress-bar'
										role='progressbar'
										style={{ width: skill.percent + '%' }}
										aria-valuenow={skill.percent}
										aria-valuemin={0}
										aria-valuemax={100}
									/>
								</div>
							</div>
						))}
				</div>
			</div>
		</section>
	);
};

export default Resume;
