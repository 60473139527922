import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';

const FAQs = () => {
	const { t, i18n } = useTranslation();
	console.log(i18n.language);
	const faqsData =
		i18n.language === 'en'
			? [
					{
						question: 'How much does a ski season cost at my level ?',
						answer: `The cost of a FIS season at my level cannot go below 20,000 euros, which is both the minimum and what I consider sufficient for my goals, but this amount can go up to 50,000 euros, which will not be necessary in my case. Therefore, I have estimated the cost of my school year, which includes attending a bi-lingual high school with a ski option, equipment, training season, including ski pass and races, to be around 30,000 euros.`,
					},
					{
						question: 'What equipment do I need?',
						answer:
							'To enter the FIS circuit, I will need a pair of training skis and a pair of racing skis for slalom, as well as two pairs for giant slalom. I also need a pair of boots, two helmets, two pairs of poles, and all the necessary clothing.',
					},
					{
						question: 'Will I join a training structure?',
						answer:
							'I do intend to join one of the various private structures in the region, as the ski club ends in the U16 category after the second year.',
					},
					{
						question: 'How can you help me?',
						answer:
							'I am looking for partnerships at various levels to support my project. Ideally, I am seeking a leading partner who shares my passion for the sport, believes in me, and would be willing to provide financial support. In exchange, this partner would become my main sponsor, and I would promote them in the desired way. I am also open to other partnerships, such as equipment suppliers, trainers, or financial aid.',
					},
					{
						question: `Does Kenya support me in this project?`,
						answer: `At the beginning of this adventure, I wanted to share my project with Kenya, specifically with the Olympic Committee, to generate interest. After the initial contacts, we had the opportunity to meet them at the Alpine Skiing World Championships in Méribel Courchevel. During this meeting, the President, the First Secretary, and the Treasurer of the Kenyan National Olympic Committee expressed a great interest in my project and validated my request to become a Kenyan athlete. They also created a Kenyan Ski Federation, of which I am now a member. I am delighted to represent Kenya and be part of this federation, which will help me achieve my sports goals and inspire other young Kenyan athletes.`,
					},
					{
						question: `What is the Association of Kenyan Skiers in France?`,
						answer: `To ensure transparency, every expense will be justified, and an Association of Kenyan Skiing in France will be created to follow my project as well as that of other athletes who wish to follow the path I want to pave. I have already made contact with a young athlete with the same dual nationality profile as me, to create a strong and committed community.`,
					},
					{
						question: `Is Kenya already represented in Alpine Skiing?`,
						answer: `Kenya made its first appearances at the Winter Olympics in 1998, 2002, and 2006 thanks to Phillip Boit, a cross-country skier. Sabrina Simader, on the other hand, represented Kenya in alpine skiing at the 2016 Winter Youth Olympics. She also participated in the 2017 World Championships and the 2018 Winter Olympics in South Korea, where a record number of eight African countries were represented, including Nigeria, Eritrea, Ghana, Madagascar, South Africa, Morocco, and Togo. Unfortunately, Sabrina was unable to represent Kenya at the 2022 Winter Olympics in China, where Africa was represented by six athletes from five African countries. This year, Sabrina Simader participated in the Alpine Skiing World Championships in Courchevel Meribel 2023, where she received greater media exposure.`,
					},
			  ]
			: [
					{
						question: 'Combien côute une saison de ski à mon niveau ?',
						answer: `Le coût d'une saison FIS à mon niveau ne peut descendre en dessous de 20 000 euros, c'est à la fois le minimum et ce que j'estime suffisant pour mes objectifs, mais ce montant peut s'élever jusqu'à 50 000 euros, une telle somme ne sera pas nécessaire dans mon cas. J'ai donc chiffré mon année scolaire, lycée bi-scolarité option ski, matériel et saison d'entraînement, y compris forfait et courses, à un montant de 30 000 euros.`,
					},
					{
						question: 'De quel matériel ais-je besoin?',
						answer: `Pour rentrer dans le circuit FIS, il me faudra une paire de ski d'entraînement et une paire de course pour le slalom, ainsi que deux paires pour le slalom géant.Il me faut ajouter une paire de chaussures, deux casques et deux paires de bâtons, plus tout l'habillement.`,
					},
					{
						question: `Vais-je intégrer une structure d'entraienement ?`,
						answer: `Je souhaite effectivement intégrer l'une des différentes structures privées de la région, car le ski club se termine en catégorie U16 deuxième année.`,
					},
					{
						question: `Comment m'aider ?`,
						answer: `Je suis à la recherche de partenariats à différents niveaux pour soutenir mon projet. Idéalement, je cherche un partenaire de premier plan qui partage ma passion pour le sport, qui croit en moi et qui serait prêt à m'accompagner financièrement. En échange, ce partenaire deviendrait mon sponsor principal et je le mettrais en avant de la manière souhaitée.
Je suis également ouvert à d'autres partenariats, tels que des fournisseurs de matériel, des accompagnateurs ou des aides financières. 
`,
					},
					{
						question: `Le Kenya me soutient-il dans ce projet ?`,
						answer: `Au départ de cette aventure, j’ai souhaité partager mon projet avec le Kenya, notamment avec le comité olympique, afin de susciter leur intérêt. Après les premiers contacts, nous avons eu l'opportunité de les rencontrer lors des Championnats du Monde de ski alpin à Méribel Courchevel.
Au cours de cette rencontre, le président, le premier secrétaire et le trésorier du comité national olympique kényan ont exprimé un grand intérêt pour mon projet et ont validé ma demande de devenir un athlète kényan. Ils ont également créé une fédération de ski kényane, dont je suis désormais membre.
Je suis ravi de pouvoir représenter le Kenya et de faire partie de cette fédération, qui va m'aider à atteindre mes objectifs sportifs et à inspirer d'autres jeunes athlètes kényans.`,
					},
					{
						question: `Qu'est ce que l'association du Ski Kenyan en France ?`,
						answer: `Pour garantir la transparence, chaque dépense sera justifiée et une Association de ski Kenyan en France sera créée pour suivre mon projet ainsi que celui d'autres athlètes qui souhaitent suivre la voie que je souhaite tracer.
J'ai déjà établi un contact avec un jeune athlète ayant le même profil de double nationalité que moi, afin de créer une communauté solide et engagée.
`,
					},
					{
						question: `Le Kenya est-il déjà représenté en ski Alpin ?`,
						answer: `Le Kenya a fait ses premières apparitions aux Jeux olympiques d'hiver en 1998, 2002 et 2006 grâce à Phillip Boit, skieur de fond. Sabrina Simader, quant à elle, a représenté le Kenya en ski alpin aux Jeux olympiques d'hiver de la jeunesse en 2016. Elle a également participé aux championnats du monde 2017 et aux Jeux olympiques d'hiver 2018 en Corée du Sud, où un nombre record de huit pays africains étaient représentés, notamment le Nigeria, l'Érythrée, le Ghana, Madagascar, l'Afrique du Sud, le Maroc et le Togo. Malheureusement, Sabrina n'a pas pu représenter le Kenya aux Jeux olympiques d'hiver de 2022 en Chine, où l'Afrique était représentée par six athlètes de cinq pays africains. Cette année, Sabrina Simader a participé aux Championnats du monde de ski alpin à Courchevel Meribel 2023, où elle a bénéficié d'une plus grande exposition médiatique.`,
					},
			  ];

	return (
		<section id='faq' className='section bg-light'>
			<div className='container'>
				<div className='row gy-5'>
					<div className='col-lg-6 order-1 order-lg-0 wow fadeInUp'>
						{/* Heading */}
						<p className='text-center text-lg-start mb-2'>
							<span className='bg-primary text-dark px-2'>
								{t('faq.section-title')}
							</span>
						</p>
						<h2 className='text-10 fw-600 text-center text-lg-start mb-5'>
							{t('faq.section-subtitle')}
						</h2>
						{/* Heading end*/}

						<Accordion flush defaultActiveKey='0'>
							{faqsData.length > 0 &&
								faqsData.map((faq, index) => (
									<Accordion.Item eventKey={index} key={index}>
										<Accordion.Header>{faq.question}</Accordion.Header>
										<Accordion.Body
											dangerouslySetInnerHTML={{ __html: faq.answer }}
										></Accordion.Body>
									</Accordion.Item>
								))}
						</Accordion>
					</div>
					<div
						className='col-lg-6 d-flex align-items-center justify-content-center order-0 order-lg-1 wow fadeIn'
						data-wow-delay='1s'
					>
						{' '}
						<img
							className='img-fluid rounded'
							src='images/Issa-2.jpg'
							title='FAQ'
							alt='faq'
						/>{' '}
					</div>
				</div>
			</div>
		</section>
	);
};

export default FAQs;
