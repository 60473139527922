import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import { scrollDuration } from '../../../config/commonConfig';
import { LanguageSwitcher } from '../../../locales/LanguageSwitcher';

import { Tooltip } from '../../Tooltip';

const FullScreenHeader = ({ textWhite }) => {
	const [stickyHeader, setStickyHeader] = useState(false);
	const [isNavModalClose, setIsNavModalClose] = useState(true);
	const { t } = useTranslation();

	const checkScrollTop = () => {
		let header = document.getElementsByClassName('primary-menu');

		if (header) {
			if (
				document.body.scrollTop > 1 ||
				document.documentElement.scrollTop > 1
			) {
				setStickyHeader(true);
			} else {
				setStickyHeader(false);
			}
		}
	};

	if (typeof window !== 'undefined') {
		window.addEventListener('scroll', checkScrollTop);
	}

	return (
		<header id='header' className='sticky-top'>
			{/* Navbar */}
			<nav
				className={
					'primary-menu navbar navbar-expand-none navbar-overlay  bg-transparent border-bottom-0 text-5 fw-600 ' +
					(stickyHeader ? 'sticky-on-top ' : ' ') +
					(textWhite
						? 'navbar-overlay navbar-text-light'
						: 'navbar-overlay-dark')
				}
			>
				<div className='container'>
					{/* Logo */}
					<Link
						className='logo'
						title='Issa'
						smooth='easeInOutQuint'
						duration={scrollDuration}
						style={{ cursor: 'pointer' }}
						to='home'
						onClick={(e) => {
							e.preventDefault();
							setIsNavModalClose(true);
						}}
					>
						<img
							src={
								textWhite
									? 'images/logo-only-small.png'
									: 'images/logo-only-small.png'
							}
							alt='Issa'
							style={{ maxWidth: '70px' }}
						/>
					</Link>

					{/* Logo End */}
					<div
						className={'text-3 ms-auto me-2 ' + (textWhite ? 'text-white' : '')}
					>
						{t('header.intro')} 🇫🇷 🇰🇪
					</div>
					<button
						className={
							'navbar-toggler collapsed ' + (isNavModalClose ? '' : 'show')
						}
						type='button'
						onClick={() => setIsNavModalClose(!isNavModalClose)}
					>
						<span />
						<span />
						<span />
					</button>
					<div
						id='header-nav'
						className={
							'collapse navbar-collapse ' + (isNavModalClose ? '' : 'show')
						}
					>
						<div className='d-flex flex-column h-100 align-items-center justify-content-center'>
							<ul className='navbar-nav'>
								<li className='nav-item'>
									<Link
										className='nav-link '
										smooth='easeInOutQuint'
										duration={scrollDuration}
										style={{ cursor: 'pointer' }}
										activeClass='active'
										spy
										to='home'
										onClick={(e) => {
											e.preventDefault();
											setIsNavModalClose(true);
										}}
									>
										{t('header.home')}
									</Link>
								</li>
								<li className='nav-item'>
									<Link
										className='nav-link '
										smooth='easeInOutQuint'
										duration={scrollDuration}
										style={{ cursor: 'pointer' }}
										activeClass='active'
										spy
										to='about'
										onClick={(e) => {
											e.preventDefault();
											setIsNavModalClose(true);
										}}
									>
										{t('header.about')}
									</Link>
								</li>
								<li className='nav-item'>
									<Link
										className='nav-link '
										smooth='easeInOutQuint'
										duration={scrollDuration}
										style={{ cursor: 'pointer' }}
										activeClass='active'
										spy
										to='services'
										onClick={(e) => {
											e.preventDefault();
											setIsNavModalClose(true);
										}}
									>
										{t('header.project')}
									</Link>
								</li>
								<li className='nav-item'>
									<Link
										className='nav-link '
										smooth='easeInOutQuint'
										duration={scrollDuration}
										style={{ cursor: 'pointer' }}
										activeClass='active'
										spy
										to='resume'
										onClick={(e) => {
											e.preventDefault();
											setIsNavModalClose(true);
										}}
									>
										{t('header.experience')}
									</Link>
								</li>
								<li className='nav-item'>
									<Link
										className='nav-link '
										smooth='easeInOutQuint'
										duration={scrollDuration}
										style={{ cursor: 'pointer' }}
										activeClass='active'
										spy
										to='portfolio'
										onClick={(e) => {
											e.preventDefault();
											setIsNavModalClose(true);
										}}
									>
										{t('header.portfolio')}
									</Link>
								</li>
								<li className='nav-item'>
									<Link
										className='nav-link '
										smooth='easeInOutQuint'
										duration={scrollDuration}
										style={{ cursor: 'pointer' }}
										activeClass='active'
										spy
										to='faq'
										onClick={(e) => {
											e.preventDefault();
											setIsNavModalClose(true);
										}}
									>
										{t('header.faq')}
									</Link>
								</li>
								<li className='nav-item'>
									<Link
										className='nav-link '
										smooth='easeInOutQuint'
										duration={scrollDuration}
										style={{ cursor: 'pointer' }}
										activeClass='active'
										spy
										to='testimonial'
										onClick={(e) => {
											e.preventDefault();
											setIsNavModalClose(true);
										}}
									>
										{t('header.supports')}
									</Link>
								</li>
								<li className='nav-item'>
									<Link
										className='nav-link '
										smooth='easeInOutQuint'
										duration={scrollDuration}
										style={{ cursor: 'pointer' }}
										activeClass='active'
										spy
										to='contact'
										onClick={(e) => {
											e.preventDefault();
											setIsNavModalClose(true);
										}}
									>
										{t('header.contact')}
									</Link>
								</li>
							</ul>
							<ul className='social-icons social-icons-light social-icons-lg social-icons-light d-inline-flex mt-4'>
								<li className='social-icons-facebook'>
									<Tooltip text='Facebook' placement='top'>
										<a
											href='https://www.facebook.com/profile.php?id=100090583073938'
											target='_blank'
											rel='noopener noreferrer'
										>
											<i className='fab fa-facebook' />
										</a>
									</Tooltip>
								</li>
								<li className='social-icons-instagram'>
									<Tooltip text='Instagram' placement='top'>
										<a
											href='https://instagram.com/issa.laborde?igshid=MzRlODBiNWFlZA=='
											target='_blank'
											rel='noopener noreferrer'
										>
											<i className='fab fa-instagram' />
										</a>
									</Tooltip>
								</li>
							</ul>
							<LanguageSwitcher />
						</div>
					</div>
				</div>
			</nav>
			{/* Navbar End */}
		</header>
	);
};

export default FullScreenHeader;
