import React from 'react';
import { useTranslation } from 'react-i18next';

import Slider from 'react-slick';

const Testimonials = () => {
	const { t, i18n } = useTranslation();
	const reviews =
		i18n.language === 'en'
			? [
					{
						name: 'Alexandre Valtadoros',
						position: 'Coach',
						src: 'images/testimonial/avatar-ski.png',
						desc: '“Issa is a good skier, he has potential and can do great things with good training”',
					},
					{
						name: 'Guillem Chardonnereau',
						position: 'Training partner',
						src: 'images/testimonial/avatar-ski.png',
						desc: ' “Issa is motivated to improve”',
					},
					{
						name: 'Gaël and Joséphine',
						position: 'My parents',
						src: 'images/testimonial/avatar-ski.png',
						desc: '“Issa has always been on his skis during all winters since he was 2 years old, he wants to take this passion higher and share it. He shows a lot of ambition in his project”',
					},
			  ]
			: [
					{
						name: 'Alexandre Valtadoros',
						position: 'Entraîneur',
						src: 'images/testimonial/avatar-ski.png',
						desc: '“Issa est un bon skieur, il a du potentiel et peux faire de belle chose grâce à un bon entraînement”',
					},
					{
						name: 'Guillem Chardonnereau',
						position: "Partenaire d'entraînement",
						src: 'images/testimonial/avatar-ski.png',
						desc: ' “Issa est motivée pour progresser”',
					},
					{
						name: 'Gaël et Joséphine',
						position: `Mes Parents`,
						src: 'images/testimonial/avatar-ski.png',
						desc: `“Issa a toujours été sur ses ski pendant tous les hivers depuis ses 2ans, il souhaite amener cette passion plus haut et le partage. Il montre beaucoup d'ambition dans son projet"`,
					},
			  ];

	const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
		<button
			{...props}
			className={
				'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
			}
			aria-hidden='true'
			aria-disabled={currentSlide === 0 ? true : false}
			type='button'
		>
			<i className='fa fa-chevron-left'></i>
		</button>
	);
	const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
		<button
			{...props}
			className={
				'slick-next slick-arrow' +
				(currentSlide === slideCount - 1 ? ' slick-disabled' : '')
			}
			aria-hidden='true'
			aria-disabled={currentSlide === slideCount - 1 ? true : false}
			type='button'
		>
			<i className='fa fa-chevron-right'></i>
		</button>
	);

	var settings = {
		dots: true,
		arrows: true,
		prevArrow: <SlickArrowLeft />,
		nextArrow: <SlickArrowRight />,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 4500,
	};

	return (
		<section id='testimonial' className='section bg-secondary'>
			<div className='container'>
				{/* Heading */}
				<p className=' text-center mb-2 wow fadeIn'>
					<span className='bg-primary text-dark px-2'>
						{t('testimonials.section-title')}
					</span>
				</p>
				<h2 className='text-10 fw-600 text-white text-center mb-5 wow fadeIn'>
					{t('testimonials.section-subtitle')}
				</h2>
				{/* Heading end*/}
				<div className='row'>
					<div className='col-lg-9 mx-auto wow fadeInUp'>
						<Slider {...settings}>
							{reviews.length > 0 &&
								reviews.map((review, index) => (
									<div className='item text-center px-5' key={index}>
										{' '}
										<span className='text-9 text-primary'>
											<i className='fa fa-quote-start' />
										</span>
										<p className='text-5 text-white'>{review.desc}</p>
										<img
											className='img-fluid d-inline-block w-auto rounded-circle shadow'
											src={review.src}
											alt={review.name}
										/>{' '}
										<strong className='d-block text-3 fw-600 text-white'>
											{review.name}
										</strong>{' '}
										<span className='text-light'>{review.position}</span>{' '}
									</div>
								))}
						</Slider>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Testimonials;
