import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from './Tooltip';

const Contact = () => {
	const { t } = useTranslation();
	return (
		<section id='contact' className='section bg-primary'>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-5 text-center text-lg-start wow fadeInUp'>
						<h2 className='text-10 fw-600 mb-5'>
							{t('contact.section-title')}
						</h2>
						<p className='text-5 mb-5'>{t('contact.text-content')} </p>
						<h3 className='text-5 fw-600'>{t('contact.email-label')}:</h3>
						<address className='text-4'>{t('contact.email-text')}</address>
						<h3 className='text-5 fw-600'>{t('contact.phone-label')}:</h3>
						<p className='text-4'>{t('contact.phone-text')}</p>
						<ul className='social-icons social-icons-lg justify-content-center justify-content-lg-start mt-5'>
							<li className='social-icons-facebook'>
								<Tooltip text='Facebook' placement='top'>
									<a
										href='https://www.facebook.com/profile.php?id=100090583073938'
										target='_blank'
										rel='noopener noreferrer'
									>
										<i className='fab fa-facebook' />
									</a>
								</Tooltip>
							</li>
							<li className='social-icons-instagram'>
								<Tooltip text='Instagram' placement='top'>
									<a
										href='https://instagram.com/issa.laborde?igshid=MzRlODBiNWFlZA=='
										target='_blank'
										rel='noopener noreferrer'
									>
										<i className='fab fa-instagram' />
									</a>
								</Tooltip>
							</li>
						</ul>
					</div>
					<div
						className='col-lg-6 ms-auto mt-5 mt-lg-0 wow fadeInUp'
						data-wow-delay='0.3s'
					>
						{/* Contact Form */}

						<form
							id='contact-form'
							className='form-border'
							name='contact'
							method='post'
						>
							<input type='hidden' name='form-name' value='contact' />
							<div className='row g-4'>
								<div className='col-12'>
									<label className='form-label' htmlFor='name'>
										{t('contact.form-name-label')}:
									</label>
									<input
										id='name'
										name='user_name'
										type='text'
										className='form-control py-1'
										required
									/>
								</div>
								<div className='col-12'>
									<label className='form-label' htmlFor='email'>
										{t('contact.form-email-label')}:
									</label>
									<input
										id='email'
										name='email'
										type='email'
										className='form-control py-1'
										required
									/>
								</div>
								<div className='col-12'>
									<label className='form-label' htmlFor='form-message'>
										{t('contact.form-text-label')}
									</label>
									<textarea
										id='form-message'
										name='message'
										className='form-control py-1'
										rows={4}
										required
										defaultValue={''}
									/>
								</div>
								<div className='col-12 text-center text-lg-start'>
									<button
										id='submit-btn'
										className='btn btn-dark rounded-0'
										type='submit'
									>
										<>
											{t('button.send')}
											<span className='ms-3'>
												<i className='fas fa-arrow-right' />
											</span>
										</>
									</button>
								</div>
							</div>
						</form>
						{/* Contact Form end */}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contact;
